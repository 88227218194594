import React from 'react'
import {format} from 'date-fns'
import "../styles/festival-artworks.css"
import {Link} from 'gatsby'
import {getArtworkUrl} from '../lib/helpers'
import PortableText from './portableText'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

function FestivalArtworks (props) {
const {artworks, cats} = props

console.log(artworks)




return (

  <div id="festival-artworkss">
  <div className="wrapper">
  <div id="festival-artworks-inner">

    <h2>Artwork</h2>

      {cats && cats.map((cat, index) => (

      <div className="artwork-cat-group">
      
      <p className="day">{cat.title}</p>

      <div className="grid">
      {artworks && artworks.filter(artworkraw => artworkraw.category.slug.current === cat.slug.current).map((artwork, index) => (
        <Link className="artwork-item width-6-12-m width-4-12" to={getArtworkUrl(artwork.festivalYear.title, artwork.slug.current)}>
        <div className="content">
            
          <div className="over">
            <h4>{artwork.title}</h4>

            {artwork.authors &&
              artwork.authors.map(author => (
              <p className="author">
                  {author.name}
              </p>    
              ))
            }
          </div>

            {artwork.images[0] &&
            <img
              src={imageUrlFor(buildImageObj(artwork.images[0]))
                .width(1000)
                .height(1000)
                .fit('crop')
                .url()}
              alt={artwork.images[0].alt}
            />
            }
            </div>
        </Link>
      ))}
      </div>

      </div>


      ))}

  </div>
  </div>
  </div>

)}

export default FestivalArtworks