import React from 'react'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
} from '../lib/helpers'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import FestivalArtworks from '../components/festival-artworks'

function ArtWorks (props) {

const {data} = props
const site = (data || {}).site
const festival = (data || {}).festival

const artworks = (data || {}).artworks
    ? mapEdgesToNodes(data.artworks)
      .filter(filterOutDocsWithoutSlugs)
    : []

const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
    : []

return (

  <Layout 
    title={site.title} 
    email={site.email} 
    footer={site.footer} 
    partners={festival.partners} 
    colorA={festival.colorA} 
    colorB={festival.colorB} 
    colorC={festival.colorC} 
    bodyFont={festival.bodyFont} 
    secondaryFont={festival.secondaryFont} 
    horizontalLogo={festival.horizontalLogo}
    instagramLink={site.instagramLink} 
    facebookLink={site.facebookLink} 
    twitter={site.twitter} 
    linkedInLink={site.linkedInLink} >
    <SEO
      title={site.title}
      author={site.author}
      description={site.description}
      keywords={site.keywords}
      image={festival.siteImage}
    />
    <Container>
      {artworks && (
        <FestivalArtworks
          artworks={artworks}
          cats={catNodes}
        />
      )}
    </Container>
  </Layout>


)}

export default ArtWorks