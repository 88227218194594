import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import ArtWorks from '../components/artworks'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      metadata {
        dimensions {
          aspectRatio
        }
        lqip
      }
      _id
    }
  }
  query ArtworksPageQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      comingSoon
      email
      author
      keywords
    }

    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }

    artworks: allSanityWork(
      sort: { fields: [title], order: ASC }
      filter: {festivalYear: {id: {eq: $id}}}
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          festivalYear {
            title
          }
          category {
            slug {
              current
            }
            title
          }
          authors {
            _rawBiography
            name
          }
          _rawDescription
          images {
            ...SanityImage
            alt
            caption
          }
          youtube {
            url
          }
          webLink
        }
      }
    }

    festival: sanityYear(id: {eq: $id}) {
      _rawIntroduction
      _rawComingSoon
      _rawCall
      title
      beginDate
      endDate
      horizontalLogo {
        ...SanityImage
        alt
      }
      stackedLogo {
        ...SanityImage
        alt
      }
      siteImage {
        ...SanityImage
        alt
      }
      colorA
      colorB
      colorC
      bodyFont
      secondaryFont
      links {
        title
        url
      }
      partners {
        title
        url
        image {
          ...SanityImage
          alt
        }
      }
    }

  }
`

const ArtworksPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  
    return (
      <ArtWorks data={data} />
    )

}

export default ArtworksPage